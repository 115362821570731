import React, { PropsWithChildren, ReactNode } from 'react';
import styles from './buttons.module.css';


const Buttons = (props: PropsWithChildren<ReactNode>) =>  {
    
    return(
        <>
        <div className={styles.buttonGroup}>
            <button className={styles.button}>Prev</button>
            <button>Random</button>
            <button>Next</button>
        </div>
        </>

    )
}

export default Buttons;