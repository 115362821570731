import React from 'react';
import styles from './Heading.module.css';


const MainHeading = () =>  {
    
    return(
        <div>
        <h1 className={styles.MainHeading}>Oddly Satisfying Things</h1>
        <p>Definitely not porn though</p>
        </div>
    )
}

export default MainHeading;