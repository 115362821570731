import React, { PropsWithChildren, ReactNode } from 'react';
import styles from './Layout.module.css';


const Layout = (props: PropsWithChildren<ReactNode>) =>  {
    
    return(
        <>
        <body>
        <div className={styles.layout}>
        {props.children}
        </div>
        </body>
        </>

    )
}

export default Layout;