import React from 'react';
import styles from './Footer.module.css';


const Footer = () =>  {
    
    return(
        <footer className={styles.footer}>
            Hi Glen and Yeni!
        </footer>

    )
}

export default Footer;