import React from 'react';
import './App.css';
import PictureFrame from './components/PictureFrame/PictureFrame';
import Layout from './layout/Layout/Layout';
import Footer from './layout/Footer/Footer';
import MainHeading from './layout/Heading/Heading';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <Layout>
      <MainHeading />
      <PictureFrame></PictureFrame>
      <Footer />  
      </Layout>
      </div>
  );
}

export default App;
