import React from 'react';
import styles from './PictureFrame.module.css';
import Buttons from '../buttons/Buttons';


const PictureFrame = () => {
    return(
        <div className={styles.container}>
            <h2 className={styles.heading}>Just a man shaping a guitar body by hand</h2>
            <div className={styles.frame}>
                <img className={styles.image} src="http://images.oddlysatisfyingthings.com/guitar.gif" alt="a man hand shaping a guitar body" />
            </div>
            <Buttons />
        </div>
    )
}

export default PictureFrame;